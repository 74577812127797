<template>
  <div id="app">
    <keep-alive>
      <van-tabbar
        v-model="active"
        active-color="#E5432C"
        inactive-color="#999999"
        v-if="this.$route.meta.hideTabBar"
        :safe-area-inset-bottom="true"
        :route="true"
      >
        <!-- :to="{ path: '/datapage', query: { id: '参数66' } }" -->
        <!-- <van-tabbar-item to="/"> -->
        <van-tabbar-item
          :to="{ path: '/', query: { serviceType: serviceType } }"
        >
          <span class="tabbar">首页</span>
          <template #icon="props">
            <img
              style="width: 24px; height: 24px"
              :src="props.active ? homeIcon.active : homeIcon.inactive"
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item
          :to="{ path: '/user', query: { serviceType: serviceType } }"
        >
          <span class="tabbar">我的</span>
          <template #icon="props">
            <img
              style="width: 24px; height: 24px"
              :src="props.active ? userIcon.active : userIcon.inactive"
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </keep-alive>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      active: 0,
      userIcon: {
        active: require("./static/image/tabs/user.png"),
        inactive: require("./static/image/tabs/user2.png"),
      },
      homeIcon: {
        active: require("./static/image/tabs/home.png"),
        inactive: require("./static/image/tabs/home2.png"),
      },
      serviceType: "",
    };
  },
  updated() {
    if (localStorage.getItem("serviceType")) {
      this.serviceType = localStorage.getItem("serviceType");
      // console.log(123);
    }
    // console.log(1111, localStorage.getItem("serviceType"));
  },
  created() {
    // localStorage.clear();
  },
  mounted() {},
  methods: {},
};
</script>

<style>
body {
  background: #f6f6f6;
  font-size: 14px;
  height: 100%;
}
.tabbar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  font-family: "PingFang SC-Medium";
}
.tabbar img {
  width: 24px !important;
  height: 24px !important;
}

/deep/ .van-tabbar-item__icon >>> img {
  width: 24px !important;
  height: 24px !important;
}
/* a {
  text-decoration: none;
  color: inherit;
} */
#app {
  width: 375px;
  box-sizing: border-box;
  margin: 0 auto;
}
.van-tabbar-item__text {
  width: 100%;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
