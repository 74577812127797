import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "@/router";
import "lib-flexible";
import "@/static/css/reset.css";
import "@/static/font/font.css"; //引入字体文件
import VueClipboard from "vue-clipboard2"; //复制功能
import axios from "axios";
Vue.prototype.$axios = axios;

import {
  Tabbar,
  TabbarItem,
  Button,
  Swipe,
  SwipeItem,
  Picker,
  Popup,
  Area,
  NavBar,
  Form,
  Field,
  SubmitBar,
  Toast,
  List,
  Icon,
  CountDown,
  Dialog,
  Empty,
  Loading,
} from "vant";

Vue.use(Loading);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(VueClipboard);
Vue.use(CountDown);
Vue.use(Icon);
Vue.use(List);
Vue.use(Toast);
Vue.use(SubmitBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(VueRouter);
Toast.setDefaultOptions({ duration: 2000 });
Vue.config.productionTip = false;
new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
