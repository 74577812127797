/*
 * @Author: tianpeixin 18617269361@163.com
 * @Date: 2023-12-22 11:26:00
 * @LastEditors: tianpeixin 18617269361@163.com
 * @LastEditTime: 2024-09-03 10:53:22
 * @FilePath: \app\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//创建路由器实例
// const router = new VueRouter({
//   routes: [
//     {
//       path: "/",
//       component: () => import("@/views/Home"),
//       meta: {
//         hideTabBar: true, //是否展示底部tabbar
//       },
//     },
//     {
//       path: "/user",
//       component: () => import("@/views/User"),
//       meta: {
//         hideTabBar: true,
//       },
//     },
//     {
//       path: "/detail",
//       component: () => import("@/views/Detail"), //号码详情
//     },
//     {
//       path: "/waitPay",
//       component: () => import("@/views/WaitPay"), //待支付页面
//     },
//     {
//       path: "/cancelOrder",
//       component: () => import("@/views/OrderDetail"), //已取消页面
//     },
//     {
//       path: "/success",
//       component: () => import("@/views/PaySuccess"), //支付成功
//     },
//     {
//       path: "/fail",
//       component: () => import("@/views/PayFailed"), //支付失败
//     },
//     {
//       path: "/login",
//       component: () => import("@/views/Login"),
//     },
//   ],
// mode: "history",
// });

// export default router;
export const constantRoutes = [
  {
    path: "/",
    component: () => import("@/views/Home"),
    meta: {
      hideTabBar: true, //是否展示底部tabbar
    },
  },
  {
    path: "/user",
    component: () => import("@/views/User"),
    meta: {
      hideTabBar: true,
    },
  },
  {
    path: "/detail",
    component: () => import("@/views/Detail"), //号码详情
  },
  {
    path: "/waitPay",
    component: () => import("@/views/WaitPay"), //待支付页面
  },
  {
    path: "/cancelOrder",
    component: () => import("@/views/OrderDetail"), //已取消页面
  },
  {
    path: "/success",
    component: () => import("@/views/PaySuccess"), //支付成功
  },
  {
    path: "/fail",
    component: () => import("@/views/PayFailed"), //支付失败
  },
  {
    path: "/login",
    component: () => import("@/views/Login"), //登录
  },
];
export default new VueRouter({
  base: "./",
  mode: "history", // 去掉url中的# 线上
  // mode: "hash", //
  routes: constantRoutes,
});
